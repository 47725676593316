<template>
  <div class="right-font">
    <div class="m-2">
      <div class="m-3 w-11/12" v-if="status !== '0'">
        <a-radio-group
          @change="onChange"
          v-model="optype"
          :disabled="chooseValue !== '1'"
        >
          <a-radio :value="1">同意</a-radio>
          <a-radio :value="2">不同意</a-radio>
        </a-radio-group>
      </div>
      <div
        class="my-3 ml-2"
        v-if="optype === 2 && billtype !== 'MBYS' && billtype !== 'BFBQ'"
      >
        <a-radio-group
          :defaultValue="value"
          size="small"
          buttonStyle="solid"
          @change="returnAndOver"
        >
          <a-radio-button value="98" class="text-xs">回退</a-radio-button>
          <a-radio-button value="15" class="text-xs">终止</a-radio-button>
        </a-radio-group>
      </div>
      <div class="w-12/12 m-2">
        <template>
          <a-textarea
            placeholder="审批意见"
            :rows="6"
            v-model="note"
            :disabled="chooseValue !== '1'"
          />
        </template>
      </div>
    </div>
    <div
      class="m-2"
      v-if="
        (billtype === 'XSSQ' ||
          billtype === 'XSBX' ||
          billtype === 'XSHYBG' ||
          billtype === 'GFHX') &&
        status !== '0'
      "
    >
      <p class="m-3 py-3 w-12/12 item-title border-b-2">金额审批</p>
      <div
        class="m-3 w-12/12"
        v-if="billtype === 'XSSQ' || billtype === 'XSHYBG'"
      >
        <p>批准总预算</p>
        <a-input
          placeholder="输入批准金额"
          class="mt-2"
          ref="upperInput"
          v-model="budgetApproveTotal"
          oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          :disabled="optype === 2 || billtype === 'XSBX' || chooseValue !== '1'"
          @focus="focus"
        />
      </div>
      <div class="m-3 w-12/12" v-if="billtype === 'XSBX'">
        <p>{{ isBookkeeper ? '财务批准报销金额:' : '批准报销总金额 :' }}</p>
        <a-input
          placeholder="输入批准金额"
          class="mt-2"
          ref="upperInput"
          v-model="reimAmountApprove"
          oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          :disabled="
            optype === 2 ||
            billtype === 'XSSQ' ||
            billtype === 'XSHYBG' ||
            chooseValue !== '1'
          "
          @focus="focus"
        />
      </div>
      <div
        class="m-3 w-12/12"
        v-if="isSpecialHcvReimTodo && billtype === 'GFHX'"
      >
        <p>{{ '批准核销金额 :' }}</p>
        <a-input
          placeholder="输入批准核销金额"
          class="mt-2"
          ref="upperInput"
          v-model="approvedSum"
          oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          :disabled="optype === 2 || billtype !== 'GFHX' || chooseValue !== '1'"
          @focus="focus"
        />
      </div>
      <div
        class="m-3 w-12/12"
        v-if="isSpecialHcvReimTodo && billtype === 'GFHX'"
      >
        <p>{{ '批准核销金额 :' }}</p>
        <a-input
          placeholder="输入批准核销金额"
          class="mt-2"
          ref="upperInput"
          v-model="approvedSum"
          oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          :disabled="optype === 2 || billtype !== 'GFHX' || chooseValue !== '1'"
          @focus="focus"
        />
      </div>
    </div>
    <div class="m-2" v-if="status !== '0' && billtype === 'JZBG'">
      <p class="m-3 py-3 w-12/12 item-title border-b-2">讲者级别审批</p>
      <div class="m-3 w-12/12">
        <a-select
          v-model="speakerLevel"
          style="width: 100%"
          :disabled="optype === 2 || chooseValue !== '1'"
        >
          <a-select-option
            v-for="{ text, value } in speakerLevelColumns"
            :value="value"
            :key="value"
          >
            {{ text }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <div class="m-2" v-if="isSpecialTodo && billtype === 'YGSSSQ'">
      <p class="m-3 py-3 w-12/12 item-title border-b-2">
        {{ getHouseDormitoryAppTypeTitle }}
      </p>
      <div class="m-3 w-12/12">
        <a-date-picker
          class="w-full"
          format="YYYY-MM-DD"
          @change="onDateChange"
        />
      </div>
    </div>

    <div class="m-2 px-3 mt-4">
      <a-button
        @click="submit"
        type="primary"
        :disabled="chooseValue !== '1'"
        :loading="loading"
      >
        提交
      </a-button>
      <a-button
        @click="submitStronge"
        type="primary"
        class="ml-4"
        :disabled="chooseValue !== '1'"
        :loading="loading"
      >
        暂存待办
      </a-button>
    </div>
  </div>
</template>

<script>
import { getActivityAppById } from '@/api/acdemicActive/acdemic-application'
import { getActivityReimbursementById } from '@/api/acdemicActive/acdemic-expense'
import { getActivityModifyById } from '@/api/acdemicActive/acdemic-modify'
import { getSpeakerModifyById } from '@/api/customer/modify'
import { getRentHouse } from '@/api/house/rent-house'
import {
  getHouseDormitory,
  updateHouseDormitory
} from '@/api/house/dormitory-house'
import { getHcvReim } from '@/api/acdemicActive/hcv-reim'
import { verityAppTodoForm } from '@/views/house/mixins/verity-mixin'
export default {
  name: 'ContentRight',
  props: ['chooseValue', 'billtype', 'pid', 'status'],
  data() {
    return {
      optype: 1,
      value: '98',
      note: '',
      loading: false, //提交后按钮进入加载状态
      budgetApproveTotal: '', // （申请单）批准总预算
      reimAmountApprove: '', //(总结单)批准总报销
      approvedSum: '', // （丙肝送检公费核销单）批准核销金额
      speakerLevel: '', // 讲者级别的id
      isBookkeeper: false, //判断是否为会计
      isAssistant: false, //判断是否为助理及内勤
      rentHouse: {}, // 药连锁住房申请详情数据
      dormitoryHouse: {}, // 药连锁公司驻地宿舍申请
      hcvReim: {}, // 丙肝送检公费核销单
      speakerLevelColumns: [
        { value: '1d9b089129654d78ab1ed0fbdac4cfb2', text: '区域级' },
        { value: '0f9514b840834b74b52c20f91b191911', text: '省级' },
        { value: 'ba6195afd769430fb2e9eb38dd5ea663', text: '城市级' },
        { value: 'af6ba24d61b74bf5ba9839d07c6e4b49', text: '国家级' },
        { value: '36af2bb425c94e0a8efa5e0cb4f6b9e3', text: '国际级' },
        { value: '2c2224cb28d443e5a8b6a49ac48fdd9d', text: '区县级' },
        { value: '2de22504c4fe400385e8a649000bd9d0', text: '区县级' },
        { value: '65eb4bb6b68347969bdd89ca569fee7d', text: '无级别' }
      ]
    }
  },
  created() {
    if (this.billtype === 'XSSQ' || this.billtype === 'XSHYBG') {
      if (this.chooseValue === '1') this.loading = true

      const api =
        this.billtype === 'XSSQ' ? getActivityAppById : getActivityModifyById

      api(this.pid)
        .then(response => {
          const flowInfoDTO = response.flowInfoDTO

          // 状态为待审批的时候取实际花销的金额
          if (
            flowInfoDTO &&
            flowInfoDTO.status === '3' &&
            response.budgetApprovetotal === 0
          ) {
            this.budgetApproveTotal = response.budgetTotal
          } else {
            this.budgetApproveTotal = response.budgetApprovetotal
          }

          this.$emit('budgetApproveTotal', this.budgetApproveTotal)
        })
        .finally(() => {
          this.loading = false
        })
    }

    if (this.billtype === 'XSBX') {
      if (this.chooseValue === '1') this.loading = true
      getActivityReimbursementById(this.pid)
        .then(response => {
          const flowInfoDTO = response.flowInfoDTO

          // 状态为待审批的时候取实际花销的金额
          if (
            flowInfoDTO &&
            flowInfoDTO.status === '3' &&
            response.reimAmountApprove === 0
          ) {
            this.reimAmountApprove = response.budgetTotal
          } else {
            this.reimAmountApprove = response.reimAmountApprove
          }

          this.$emit('reimAmountApprove', this.reimAmountApprove)
        })
        .finally(() => {
          this.loading = false
        })
    }

    if (this.billtype === 'GFHX') {
      if (this.chooseValue === '1') this.loading = true
      getHcvReim(this.pid)
        .then(response => {
          const flowInfoDTO = response.flowInfoDTO

          if (flowInfoDTO && flowInfoDTO.nodePolicy === '特殊审批') {
            this.approvedSum = response.actualInvest
            this.$emit('approvedSum', this.approvedSum)
          }

          this.hcvReim = response
        })
        .finally(() => {
          this.loading = false
        })
    }

    if (this.billtype === 'JZBG') {
      getSpeakerModifyById(this.pid).then(res => {
        if (res.speakerLevel) {
          this.speakerLevel = res.speakerLevel
        }
      })
    }

    // 药连锁住房申请
    if (this.billtype === 'ZFSQ') {
      if (this.chooseValue === '1') this.loading = true
      getRentHouse(this.pid)
        .then(res => {
          this.rentHouse = res
        })
        .finally(() => {
          this.loading = false
        })
    }

    if (this.billtype === 'YGSSSQ') {
      if (this.chooseValue === '1') this.loading = true
      getHouseDormitory(this.pid)
        .then(res => {
          this.dormitoryHouse = res
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  computed: {
    // 住/退/换房信息
    getHouseDormitoryAppTypeTitle() {
      return this.dormitoryHouse.dormitoryType
        ? `${this.dormitoryHouse.dormitoryTypeName}日期`
        : '日期'
    },

    // 药连锁住房申请
    isSpecialTodo() {
      return (
        this.dormitoryHouse.flowInfoDO &&
        this.dormitoryHouse.flowInfoDO.nodePolicy === '特殊审批'
      )
    },

    // 药连锁住房申请
    isSpecialHcvReimTodo() {
      return (
        this.hcvReim.flowInfoDTO &&
        this.hcvReim.flowInfoDTO.nodePolicy === '特殊审批'
      )
    }
  },

  mounted() {
    const roles = this.$store.getters['roles']
    for (let item of roles) {
      // 判断是否为会计
      if (item.code === 'cs_11') {
        this.isBookkeeper = true
      }
      // 判断是不是助理或内勤
      if (item.code === 'cs_13') {
        this.isAssistant = true
      }
    }
  },
  methods: {
    focus() {
      // 助理角色和审批通过无法修改金额
      if (this.status === '0' || this.isAssistant) {
        this.$refs.upperInput.blur()
      }
    },
    onChange() {
      if (this.optype === 1) {
        this.$emit('optype', this.optype)
      }
      if (this.optype === 2) {
        if (this.billtype === 'MBYS' || this.billtype === 'BFBQ') {
          this.budgetApproveTotal = ''
          this.$emit('optype', '15')
          return
        }

        this.budgetApproveTotal = ''
        this.$emit('optype', this.value)
      }
    },

    onDateChange(_, dateString) {
      this.dormitoryHouse.executeDate = dateString
    },

    returnAndOver(e) {
      this.$emit('optype', e.target.value)
    },
    // 提交暂存代办
    submitStronge() {
      this.value = '97'
      this.$emit('optype', this.value)
      this.$emit('submit')
    },
    // 打开提交按钮的加载状态
    openLoading() {
      this.loading = true
    },
    // 关闭提交按钮的加载状态
    closeLoading() {
      this.loading = false
    },
    submit() {
      if (this.billtype === 'ZFSQ' && !verityAppTodoForm(this.rentHouse)) return

      if (this.billtype === 'YGSSSQ' && this.isSpecialTodo) {
        if (!this.dormitoryHouse.executeDate) {
          this.$message.error('请选择住/退/换房日期')
          return
        }

        updateHouseDormitory(this.dormitoryHouse).then(() => {
          this.$emit('submit')
        })
      } else {
        this.$emit('submit')
      }
    }
  },
  watch: {
    budgetApproveTotal(nval) {
      this.$emit('budgetApproveTotal', nval)
    },
    reimAmountApprove(nval) {
      this.$emit('reimAmountApprove', nval)
    },
    speakerLevel(nval) {
      this.$emit('speakerLevel', nval)
    },
    approvedSum(nval) {
      this.$emit('approvedSum', nval)
    },
    note(nval) {
      this.$emit('note', nval)
    }
  }
}
</script>

<style lang="less" scoped>
.right-font {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(102, 102, 102, 1) !important;
}
.item-title {
  color: #4587f4;

  font-size: 14px;
  font-weight: 600;
}
/deep/.ant-btn-primary {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
  &:hover {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
  &:focus {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
}
</style>
