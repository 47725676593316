<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handClose"
      @cancel="handClose"
      width="56%"
      :title="`${title || uploadName}附件上传`"
      :closable="false"
      :keyboard="false"
      :mask="false"
      :maskClosable="false"
      cancelText="关闭"
      dialogClass="upload-preview-file"
    >
      <div class="card-containers">
        <div class="clearfix">
          <div class="file-list-card">
            <div
              class="file-list"
              :title="file.oname"
              v-for="file in fileList"
              :key="file.id"
              @click="preview(file)"
            >
              <a-icon
                type="close-circle"
                class="icon-close-btn"
                title="删除"
                @click.stop="remove(file.id)"
              />
              <div class="file-info">
                <img :src="file.src" />
              </div>
              <div class="file-name">{{ file.oname }}</div>
            </div>
          </div>
          <div class="add-file" @click="chooseFile">
            <span class="file-btn">
              <input
                type="file"
                :multiple="true"
                accept="
                image/gif, image/jpeg, image/jpg
                image/png, application/pdf,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style="display: none"
                @change="fileChange"
                ref="file"
              />
              <div>
                <span class="inline-flex flex-col" v-if="uploadLoading">
                  <a-icon class="upload-icon" type="loading" />
                  <span class="upload-text mt-2">上传文件...</span>
                </span>

                <a-icon v-else class="upload-icon" type="cloud-upload" />
              </div>
            </span>
          </div>
        </div>
      </div>
    </a-modal>

    <image-preview
      v-if="showPreviewImage"
      :imageList="imageList"
      :imageIndex="imageIndex"
      @close="showPreviewImage = false"
    ></image-preview>
    <excel-preview
      v-if="showPreviewExcel"
      :fileId="fileId"
      @close="showPreviewExcel = false"
    ></excel-preview>
  </div>
</template>

<script>
import { getBatch, getUpload, getIMGURL } from '@/api/acdemicActive/upload'
import ImagePreview from '@/components/Preview/ImagePreview'
import ExcelPreview from '@/components/Preview/ExcelPreview'

export default {
  name: 'uploadModal',
  components: { ImagePreview, ExcelPreview },
  props: {
    uploadName: {
      type: String
    },
    uploadFilelistInfo: {
      type: String
    }
  },
  data() {
    return {
      uploadLoading: false,
      visible: true,
      title: '', //modal的title
      showPreviewImage: false,
      showPreviewExcel: false,
      fileId: '', //传入pdf预览框的文件id
      fileList: [], //上传成功的文件列表
      imageList: [], //所有图片的集合列表
      imageIndex: 0 //打开图片预览的索引
    }
  },
  created() {
    if (this.uploadName === 'participantsList') this.title = '预邀参会人员名单'
    if (this.uploadName === 'activityInvitation')
      this.title = '自办活动议程和邀请函'
    if (this.uploadName === 'contractScan') this.title = '已签批合同扫描件'
    if (this.uploadName === 'sponsorQualification')
      this.title = '主办方资质证明'
    if (this.uploadName === 'sponsorInvitation') this.title = '主办方邀请函'
    if (this.uploadName === 'contract') this.title = '已签批协办协议/合同'
    if (this.uploadName === 'pubScheme') this.title = '品牌信息-发布方案'
    if (this.uploadName === 'partnerContract') this.title = '品牌信息-发布合同'
    if (this.uploadName === 'projectSummary') this.title = '信息发布-项目总结'
    if (this.uploadName === 'articleNetworkLink')
      this.title = '信息发布-文章网络连接'
    if (this.uploadName === 'signinForm') this.title = '签到表'
    if (this.uploadName === 'pictures') this.title = '现场照片'
    if (this.uploadName === 'consultationFeeProof')
      this.title = '专家讲者资费凭证'
    if (this.uploadName === 'activitySummary') this.title = '活动总结'
    if (this.uploadName === 'cooperationInfo') this.title = '协办方资料'
    if (this.uploadName === 'comment') this.title = '评论'
    if (this.uploadName === 'replay') this.title = '回复'
    if (this.uploadName === 'partner') this.title = '协议上传'

    if (this.uploadFilelistInfo) {
      this.fileList = JSON.parse(this.uploadFilelistInfo)
      if (this.fileList) {
        for (let file of this.fileList) {
          if (!['pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(file.type)) {
            file.src = ''
            getIMGURL(file.id).then(response => {
              file.src = response
              this.imageList.push(file)
            })
          } else {
            if (!file.src) {
              if (file.type === 'pdf') {
                file.src = require('@/assets/upload/pdf.png')
              } else if (file.type === 'docx' || file.type === 'doc') {
                file.src = require('@/assets/upload/word.png')
              } else if (file.type === 'xls' || file.type === 'xlsx') {
                file.src = require('@/assets/upload/excel.png')
              }
            }
          }
        }
      }
    }
  },
  methods: {
    handClose() {
      const obj = {}
      obj.uploadName = this.uploadName
      if (this.fileList.length !== 0) {
        obj.fileListInfo = JSON.stringify(
          this.fileList.map(item => {
            return { ...item, ...{ src: '' } }
          })
        )
        this.$emit('hasUpload', obj)
      } else {
        obj.fileListInfo = ''
        this.$emit('hasUpload', obj)
      }
      this.$emit('close')
    },
    chooseFile() {
      this.$refs.file.value = null
      this.$refs.file.click()
    },

    //预览pdf
    preview(file) {
      if (['doc', 'docx', 'xls', 'xlsx', 'pdf'].includes(file.type)) {
        this.fileId = file.id
        this.showPreviewExcel = true
      } else {
        for (let i = 0; i < this.imageList.length; i++) {
          if (this.imageList[i].id === file.id) {
            this.imageIndex = i
            break
          }
        }
        this.showPreviewImage = true
      }
    },

    // 文件上传
    fileChange() {
      if (this.uploadLoading) {
        this.$message.warning('文件正在上传中，请稍后重新上传')
        return
      }

      this.uploadLoading = true

      let myfile = this.$refs.file
      if (myfile.files[0] === undefined) {
        this.$message.warning('未上传任何文件！')
      } else {
        // 上传单个文件
        if (myfile.files.length === 1) {
          const curSize = myfile.files[0].size / 1024 / 1024
          const isLt30M = myfile.files[0].size / 1024 / 1024 < 30 // 判断上传文件大小

          if (!isLt30M) {
            this.$message.error(
              `本次上传附件(${myfile.files[0].name})大小为:${curSize}M，超过上传最大限制 30 M，请将附件拆分后再上传`
            )
            return
          }

          let formData = new FormData()
          formData.append('file', myfile.files[0])
          this.uploadFile(formData)
        } else {
          for (let i = 0; i < myfile.files.length; i++) {
            const curSize = myfile.files[i].size / 1024 / 1024
            const isLt30M = myfile.files[i].size / 1024 / 1024 < 30 // 判断上传文件大小

            if (!isLt30M) {
              this.$message.error(
                `本次上传附件(${myfile.files[i].name})大小为:${curSize}M，超过上传最大限制 30 M，请将附件拆分后再上传`
              )
            }

            return
          }

          // 批量上传文件
          let formData = new FormData()
          for (let item of myfile.files) {
            formData.append('files', item)
          }
          this.uploadBatchFile(formData)
        }
      }
    },

    //删除某个文件
    remove(id) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].id === id) {
          this.$message.success(`${this.fileList[i].oname}删除成功`)
          this.fileList.splice(i, 1)
          this.$store.dispatch('uploadFile/pushDelFiles', id)
        }
      }
    },

    /**
     * 上传单个文件
     * @param {File} file
     */
    uploadFile(file) {
      getUpload(file)
        .then(response => {
          const file = response
          if (file.type === 'pdf') {
            file.src = require('@/assets/upload/pdf.png')
            this.fileList.push(file)
          } else if (file.type === 'docx' || file.type === 'doc') {
            file.src = require('@/assets/upload/word.png')
            this.fileList.push(file)
          } else if (file.type === 'xls' || file.type === 'xlsx') {
            file.src = require('@/assets/upload/excel.png')
            this.fileList.push(file)
          } else {
            getIMGURL(file.id).then(response => {
              file.src = response
              this.fileList.push(file)
            })
          }
          this.$store.dispatch('uploadFile/pushAddFiles', file.id)
          this.$message.success(`${file.oname}上传成功`)
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
        .finally(() => {
          this.uploadLoading = false
        })
    },

    /**
     * 批量上传文件
     * @param {Array} fileList
     */
    uploadBatchFile(fileList) {
      getBatch(fileList)
        .then(response => {
          const list = response
          for (let file of list) {
            if (file.type === 'pdf') {
              file.src = require('@/assets/upload/pdf.png')
              this.fileList.push(file)
            } else if (file.type === 'docx' || file.type === 'doc') {
              file.src = require('@/assets/upload/word.png')
              this.fileList.push(file)
            } else if (file.type === 'xls' || file.type === 'xlsx') {
              file.src = require('@/assets/upload/excel.png')
              this.fileList.push(file)
            } else {
              getIMGURL(file.id).then(response => {
                file.src = response
                this.fileList.push(file)
              })
            }
            this.$store.dispatch('uploadFile/pushAddFiles', file.id)
          }
          this.$message.success('上传成功')
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
        .finally(() => {
          this.uploadLoading = false
        })
    }
  }
}
</script>

<style lang="less"></style>
